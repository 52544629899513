import './App.css';
import React, { useState } from 'react';
import RouterMod from './RouterMod';
import { Helmet } from 'react-helmet'

function App() {
  const [cartItems, setcartItems] = useState([]);

  function handleAddProduct(product) {
    const ProductExist = cartItems.find((item) => item.id === product.id);
    if (ProductExist) {
      setcartItems(cartItems.map((item) => item.id === product.id ?
        { ...ProductExist, quantity: ProductExist.quantity + 1 } : item
      ));
    }
    else {
      setcartItems([...cartItems, { ...product, quantity: 1 }]);
    }
  }





  return (
    <div className="App">
      <Helmet>
        <title>Foody Inn Indian & Chinese Foods</title>
      </Helmet>

      <RouterMod handleAddProduct={handleAddProduct} cartItems={cartItems} />
    </div>
  );
}

export default App;
