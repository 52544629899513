import React, { useState } from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import Home from './Home';
import Account from './Account';
import Logout from './Logout';
import Navhead from './Navhead';
import Nopage from './Nopage';
import Proddetails from './Proddetails';
import SearchProduct from './SearchProduct';
import OrderOnline from './OrderOnline';
import Dashboard from './Dashboard';
import { Indian } from './Indian';
import { Chinese } from './Chinese';
import { Tandoor } from './Tandoor';
import { Veg } from './Veg';
import Menu from './Menu';
import Cart from './Cart';
import Basket from './Basket';
import Checkout from './checkout';
import Ratelogin from './Ratelogin';
import Simredirect from './Simredirect';
import Wallet from './Wallet';
import Accountdetails from './Accountdetails';
import Orders from './Orders';
import Deliveryaddress from './Deliveryaddress';
import Newdeladd from './Newdeladd';
import Prodetails from './Prodetails';

function RouterMod(props) {

  const [cartItems, setCartItems] = useState([]);
  const onAdd = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist) {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
        )
      );
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }]);
    }
  };
  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.id !== product.id));
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
        )
      );
    }
  };
  console.warn("cartitems router", cartItems);




  return (
    <div>

      <Routes>
        <Route path="/" element={<Home cartItems={cartItems} />} />

        <Route path="/proddetails/:id/:name" element={<Proddetails cartItems={cartItems} onAdd={onAdd} />} />
        <Route path="/prodetails" element={<Prodetails cartItems={cartItems} onAdd={onAdd} />} />
        <Route path="/ratelogin/:id" element={<Ratelogin cartItems={cartItems} />} />
        <Route path="/simredirect/:id" element={<Simredirect cartItems={cartItems} />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/navhead" element={<Navhead cartItemnav={cartItems.length} onAdd={onAdd} onRemove={onRemove} />} />
        <Route path="/search" element={<SearchProduct cartItems={cartItems} />} />
        <Route path="/dashboard" element={<Dashboard cartItems={cartItems} />} />
        <Route path="/wallet" element={<Wallet cartItems={cartItems} />} />
        <Route path="/newdeliveryaddress" element={<Newdeladd cartItems={cartItems} />} />
        <Route path="/accountdetails" element={<Accountdetails cartItems={cartItems} />} />
        <Route path="/orders" element={<Orders cartItems={cartItems} />} />
        <Route path="/deliveryaddress" element={<Deliveryaddress cartItems={cartItems} />} />
        <Route path="/orderonline" element={<OrderOnline cartItems={cartItems} />} />
        <Route path="/account" element={<Account cartItems={cartItems} />} />
        <Route path="/indianfoods" element={<Indian cartItems={cartItems} />} />
        <Route path="/chinesefoods" element={<Chinese cartItems={cartItems} />} />
        <Route path="/tandoorfoods" element={<Tandoor cartItems={cartItems} />} />
        <Route path="/vegfoods" element={<Veg cartItems={cartItems} />} />
        <Route path="/cart" element={<Basket cartItems={cartItems} onAdd={onAdd} onRemove={onRemove} />} />
        <Route path="/checkout" element={<Checkout cartItems={cartItems} onAdd={onAdd} onRemove={onRemove} />} />
        <Route path="/menu/:id" element={<Menu cartItems={cartItems} />} />
        <Route path="/*" element={<Nopage cartItems={cartItems} />} />
      </Routes>
    </div>
  );
}
export default RouterMod;