import React, { useEffect, useState } from 'react';
import Navhead from './Navhead';
import { Link, NavLink } from 'react-router-dom';
import './Home.css';

import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import Footer from './Footer';


function Home(props) {
    let cartItems = props.cartItems;
    const [data, setData] = useState([]);
    const [dataindian, setDataindian] = useState([]);
    const [datachinese, setdatachinese] = useState([]);
    const [tandoor, setTandoor] = useState([]);
    const [veg, setVeg] = useState([]);

    useEffect(() => {

        fetch("https://foodyinn.in/api/fetchallproducts.php").then((result) => {
            result.json().then((resp) => {

                setData(resp);
                console.warn("RESP", resp);
            })
        });

        fetch("https://foodyinn.in/api/fetchproductsindian.php").then((resultindian) => {
            resultindian.json().then((respindian) => {

                setDataindian(respindian);
                console.warn("RESPindian", respindian);
            })
        });
        fetch("https://foodyinn.in/api/fetchproductschinese.php").then((resultchinese) => {
            resultchinese.json().then((respchinese) => {

                setdatachinese(respchinese);
                console.warn("RESPchinese", respchinese);
            })
        });
        fetch("https://foodyinn.in/api/fetchproductstandoor.php").then((resulttandoor) => {
            resulttandoor.json().then((resptandoor) => {

                setTandoor(resptandoor);
                console.warn("RESPtandoor", resptandoor);
            })
        });

        fetch("https://foodyinn.in/api/fetchproductsveg.php").then((resultveg) => {
            resultveg.json().then((respveg) => {

                setVeg(respveg);
                console.warn("RESPveg", respveg);
            })
        });


    }, [])

    const options = {
        stagePadding: 39,
        nav: false,
        dots: false,
        margin: 10,
        loop: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    const optionswn = {
        loop: false,
        nav: false,

        dots: false,
        margin: 60,
        arrow: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 5
            }
        }
    };




    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />

            <OwlCarousel options={options} style={{ marginTop: '1px' }} >
                <div><img id="slidemain" src={"https://www.foodyinn.in/imgms/1slide13.jpg"} alt="No" style={{ borderRadius: '15px' }} /></div>
                <div><img id="slidemain" src={"https://www.foodyinn.in/imgms/3tangri_kabab.jpg"} alt="No" style={{ borderRadius: '15px' }} /></div>
                <div><img id="slidemain" src={"https://www.foodyinn.in/imgms/2slide11.jpg"} alt="No" style={{ borderRadius: '15px' }} /></div>
            </OwlCarousel>




            <div class="container-fluid">
                <br />
                <div class="row justify-content-center">
                    <div class="col-sm-3">
                        <Link to="/orderonline"><button class="btn btn-outline-primary">Order Online</button></Link>
                    </div>
                </div>

                <br />
                <div class="row">
                    <div class="col-sm-12">
                        <h3><i>Whats New!</i></h3>
                    </div>
                </div>
                <br />
                {/* <div class="row">
    <div class="col-sm-12">
        <h3 align={"left"}><i>Indian</i></h3>
    </div>
</div>
 */}

                <div class="row">
                    <div class="col-sm-12">
                        <OwlCarousel options={optionswn}  >
                            {
                                data.map((item) =>
                                    <NavLink to={"/prodetails?item=" + item.id + "&title=" + item.name} style={{ textDecoration: 'none' }}>

                                        <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                            <img src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.thumbnail} />
                                            <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.name}</div>
                                        </div>
                                    </NavLink>

                                )
                            }

                        </OwlCarousel>
                    </div>
                  </div>

                <br />
                <div class="row">
                    <div class="col-sm-12">
                        <h3 align={"left"}><i>Indian</i></h3>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <OwlCarousel options={optionswn}  >
                            {
                                dataindian.map((item) =>
                                    <NavLink to={"/prodetails?item=" + item.id + "&title=" + item.name} style={{ textDecoration: 'none' }}>

                                        <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                            <img src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.thumbnail} />
                                            <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.name}</div>
                                        </div>
                                    </NavLink>

                                )
                            }

                        </OwlCarousel>
                    </div>
                </div>

                <br />
                <div class="jumbotron jumbotron-fluid bg-warning" style={{ borderRadius: '25px' }}>
                    <div class="container">
                        <h1>Exclusive Offers</h1>
                        <p>Visit our outlet to get exclusive discounts on all food items</p>
                    </div>
                </div>
                <br />

                <div class="row">
                    <div class="col-sm-12">
                        <h3 align={"left"}><i>Chinese</i></h3>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <OwlCarousel options={optionswn}  >
                            {
                                datachinese.map((item) =>
                                    <NavLink to={"/prodetails?item=" + item.id + "&title=" + item.name} style={{ textDecoration: 'none' }}>

                                        <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                            <img src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.thumbnail} />
                                            <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.name}</div>
                                        </div>
                                    </NavLink>

                                )
                            }

                        </OwlCarousel>
                    </div>
                </div>

                <br />
                <div class="row">
                    <div class="col-sm-12">
                        <h3 align={"left"}><i>Tandoor</i></h3>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <OwlCarousel options={optionswn}  >
                            {
                                tandoor.map((item) =>
                                    <NavLink to={"/prodetails?item=" + item.id + "&title=" + item.name} style={{ textDecoration: 'none' }}>

                                        <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                            <img src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.thumbnail} />
                                            <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.name}</div>
                                        </div>
                                    </NavLink>

                                )
                            }

                        </OwlCarousel>
                    </div>
                </div>

                <br />
                <div class="row">
                    <div class="col-sm-12">
                        <h3 align={"left"}><i>Veg Items</i></h3>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <OwlCarousel options={optionswn}  >
                            {
                                veg.map((item) =>
                                    <NavLink to={"/prodetails?item=" + item.id + "&title=" + item.name} style={{ textDecoration: 'none' }}>

                                        <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                            <img src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.thumbnail} />
                                            <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.name}</div>
                                        </div>
                                    </NavLink>

                                )
                            }

                        </OwlCarousel>
                    </div>
                </div>



            </div>

            <Footer />
        </div>
    );
}
export default Home;