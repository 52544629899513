import React from 'react';

import Navhead from './Navhead';

import { Helmet } from 'react-helmet';

export default function OrderOnline(props) {
   let cartItems = props.cartItems;
   return (
      <div>
         <Navhead cartItemnav={cartItems.length} />
         <Helmet>
            <meta charSet="utf-8" />
            <title>Order Online - Foody Inn Indian & Chinese Foods</title>
            <meta property="title" content="Order Online - Foody Inn Indian & Chinese Foods" />
            {/*  <meta property="og:image" content="<img src='/assets/icon.png' />" />
                            <meta property="og:url" content="https://www.foodyinn.in/" /> */}
            <meta property="description" content="FOODY INN Indian & Chinese Foods" />
         </Helmet>
         <div class="container-fluid">
            <div class="container">
               <br /><br />
               <div class="row">
                  <div class="col-sm-12 d-flex justify-content-center">
                     <p style={{ fontSize: "3vw" }}>Order Online</p>
                  </div>
               </div>

               <div class="row">
                  <div class="col-sm-12 d-flex justify-content-center">
                     <p style={{ fontSize: '2vw' }} >Please Click on your favourite Delivery Partner </p>
                  </div>
                  <div class="col-sm-6">
                     <div class="d-flex justify-content-center" style={{ paddingBottom: '10px' }}>
                        <a href="https://www.zomato.com/kolkata/foody-inn-sodepur" target="_blank" title="FOODY INN ON ZOMATO"><img src={"https://www.foodyinn.in/img/zomato_logo.png"} class="rounded" title="FOODY INN ON ZOMATO" alt="ZOMATO" height="100px" width="100px" /></a>
                     </div>
                  </div>
                  <div class="col-sm-6">
                     <div class="d-flex justify-content-center" style={{ paddingBottom: '10px' }}>
                        <a href="https://www.swiggy.com/restaurants/foody-inn-sodepur-kolkata-377123" target="_blank" title="FOODY INN ON SWIGGY"><img src={"https://www.foodyinn.in/img/swiggy.jpg"} class="rounded" title="FOODY INN ON SWIGGY" alt="SWIGGY" height="100px" width="100px" /></a>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </div>
   );

}