import React from 'react';

function Footer(){
    return(
        <div>

<footer class="page-footer font-small" style={{backgroundColor: '#333333', color:'white', marginTop:'50px'}}>

  <div style={{backgroundColor: '#666666'}}>
    <div class="container">

   
      <div class="row py-4 d-flex align-items-center">

   
        <div class="col-lg-12 text-center">
          <h5 class="mb-0 d-flex justify-content-center" style={{color:'#FFF'}}>Get In Touch With Us</h5>
        </div>
   
      </div>
   

    </div>
  </div>


  <div class="container text-center text-md-left mt-5">


    <div class="row mt-3">


      <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">


        <h6 class="text-uppercase font-weight-bold">FOODY INN</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
        <p>FOODY INN located in H.B. Town Sodepur, Kolkata. It is one of the best fast food hub in Sodepur.</p>

      </div>
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

        
        <h6 class="text-uppercase font-weight-bold">Products</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: '60px'}}/>
        <p>
          <a href="category?id=1/welcome/Bedroom/" style={{color:'#FFFFFF'}}>Indian</a>
        </p>
        <p>
          <a href="category?id=2/welcome/Living Room/" style={{color:'#FFFFFF'}}>Chinese </a>
        </p>
        <p>
          <a href="category?id=3/welcome/Dining/" style={{color:'#FFFFFF'}}>Veg</a>
        </p>
        <p>
          <a href="about?welcome/AboutUs/" style={{color:'#FFFFFF'}}>Non Veg</a>
        </p>

      </div>

      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">


        <h6 class="text-uppercase font-weight-bold">Useful links</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: '60px'}}/>
        <p>
          <a href="#"  style={{color:'#FFFFFF'}}>My Account</a>
        </p>
        <p>
          <a href="faq.php" style={{color:'#FFFFFF'}}>FAQ</a>
        </p>
        <p>
          <a href="#" style={{color:'#FFFFFF'}}>Privacy Policy</a>
        </p>
        <p>
          <a href="#"  style={{color:'#FFFFFF'}}>Terms of Service</a>
        </p>
        <p>
          <a href="#" style={{color:'#FFFFFF'}}>Refund Policy</a>
        </p>
        <p>
          <a href="contact" style={{color:'#FFFFFF'}}>Contact Us</a>
        </p>          

      </div>

      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">


        <h6 class="text-uppercase font-weight-bold">Contact</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: '60px'}} />
        <p>
          <i class="fa fa-home"></i> H.B. Town Sodepur, Kolkata, 700110, West Bengal, India</p>

        <p>
          <i class="fa fa-phone-square"></i> +91 9143126662</p>
       
        <img src="https://www.foodyinn.in/image/Payments.png" width="100%"/>
      </div>
  

    </div>
  

  </div>
  

  
  <div class="footer-copyright text-center py-3" style={{ backgroundColor:'#000000', fontSize:'14px', letterSpacing:"2px"}}>© 2021 FOODY INN. All Rights Reserved. Design and Developed by team
    <a href="https://www.glamsureworld.com/" style={{color:'#FFFFFF'}}> Glamsure</a>
  </div>
  

</footer>

        </div>
    )
}

export default Footer;