import React, { useState, useEffect, useMemo } from 'react';

import { useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import './Proddetails.css';
import Footer from './Footer';
import Navhead from './Navhead';

function Proddetails(props) {
    console.warn("propsdet", props);
    let cartItems = props.cartItems;
    let prodid = useParams();
    let itemid = prodid.id;
    const [data, setData] = useState([]);
    const [rating, setRating] = useState([]);
    const [ratingavg, setRatingavg] = useState([]);
    const [ratingreview, setRatingreview] = useState([]);
    const { product, onAdd } = props;
    const [similar, setSimilar] = useState([]);
    const navigate = useNavigate();
    const [revbtn, setRevbtn] = useState("Save Review");

    const emailcheck = JSON.parse(localStorage.getItem('emaillocal'));

    function Ratepro() {
        if (emailcheck) {

        }
        else {
            navigate('/ratelogin/' + prodid.id);
        }

    }

    async function Singleproduct() {
        let result = await fetch('https://foodyinn.in/api/singleproduct.php?prod=' + prodid.id);
        result = await result.json();
        setData(result);
        console.warn("data", data);
    }
    function Similaritems(){
        Singleproduct();
    }
    useMemo(() => {
        Singleproduct();
        Singleproduct();
    }, [])

    useMemo(async () => {
        let resultsimilar = await fetch('https://foodyinn.in/api/fetchsimilar.php?prod=' + prodid.id);
        resultsimilar = await resultsimilar.json();
        setSimilar(resultsimilar);
        console.warn("data", similar);
    }, [])


    useMemo(async () => {
        let resultrating = await fetch('https://foodyinn.in/api/fetchratings.php?prod=' + prodid.id);
        resultrating = await resultrating.json();
        setRating(resultrating.count);
        setRatingavg(resultrating.average);
        console.warn("rating", rating);
    }, [])

    useMemo(async () => {
        let resultrr = await fetch('https://foodyinn.in/api/fetchallratings.php?prod=' + prodid.id);
        resultrr = await resultrr.json();
        setRatingreview(resultrr);
        console.warn("ratingrr", ratingreview);
    }, [])


    const [pincode, setPincode] = useState("");
    const [pindata, setPindata] = useState("");
    function saveUser() {
        console.warn(pincode);

        let data = { pincode };

        fetch("https://www.foodyinn.in/api/fetchpincode.php", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)


        }).then((result) => {
            // console.warn("result",result);
            result.json().then((resp) => {
                console.warn("resp", resp);
                setPindata(resp);
                localStorage.setItem("pincode", JSON.stringify(pincode));



            })
        })
    }

    const [ratings, setRatings] = useState("");
    const [title, setTitle] = useState("");
    const [comments, setComments] = useState("");

    const handleChange = (event) => {
        setRatings(event.target.value)
    }

    function saveRating() {
        console.warn("rating:", ratings, title, comments, itemid, emailcheck);

        let data = { ratings, title, comments, itemid, emailcheck };

        fetch("https://www.foodyinn.in/api/saveitemrating.php", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)


        }).then((result) => {
            // console.warn("result",result);
            result.json().then((resp) => {
                console.warn("resp", resp);
                setRevbtn(resp);

            })
        })
    }





    const optionswn = {
        loop: false,
        nav: false,

        dots: false,
        margin: 60,
        arrow: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 5
            }
        }
    };





    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />


            {
                data.map((item) =>

                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12" align={'left'}>
                                <Link to="/" style={{ color: '#000000', fontSize: '12px' }}>Home &gt; </Link>
                                <Link to="#" style={{ color: '#000000', fontSize: '12px' }}>{item.name}  </Link>

                            </div>
                        </div>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{item.name} - Foody Inn Indian & Chinese Foods</title>
                            <meta property="title" content={item.name + ` - Foody Inn Indian & Chinese Foods`} />
                           {/*  <meta property="og:image" content="<img src='/assets/icon.png' />" />
                            <meta property="og:url" content="https://www.foodyinn.in/" /> */}
                            <meta property="description" content="FOODY INN Indian & Chinese Foods" />
                        </Helmet>


                        <div class="row">
                            <div class="col-sm-6" align={'left'}>
                                <img src={"https://foodyinn.in/products/" + item.thumbnail} class="img-fluid rounded" style={{ height: "auto", width: "450px", borderRadius: "5px" }} alt={item.thumbnail} />
                            </div>

                            <div class="col-sm-6" align={'left'}>
                                <h2>{item.name}</h2>
                                <h6><div><span class="badge badge-success"><small>{ratingavg} / 5</small></span> <span class="rating-reviews" style={{ color: 'gray' }}><i>{rating} Rating & Reviews</i></span></div></h6>
                                <br />
                                <h6>{item.description}</h6>
                                <h5>Rs. {item.price}</h5>

                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" value={pincode} onChange={(e) => { setPincode(e.target.value) }} placeholder="Enter Pincode" name="pincode" />
                                    <div class="input-group-append">
                                        <button type="submit" class="btn btn-outline-primary" onClick={saveUser}> Check </button>
                                    </div>
                                </div>


                                <div class="form-group" style={{ color: 'red', height: '35px' }}>
                                    {pindata}
                                </div>
                                <Link to="/orderonline"><button class="btn btn-outline-primary">Other Delivery Options</button></Link>



                                <br /><br /><br /><br />

                                <div id='abd'>
                                    <nav class='navbar navbar-expand' style={{ padding: '0px' }}>
                                        <button type='hidden' class='btn btn-outline-primary btn-block d-none'>Primary</button>
                                        <button type='submit' class='btn btn-primary btn-block btn-lg' onClick={() => onAdd(item)} >Add To Cart</button>
                                        <button type='submit' class='btn btn-warning btn-block btn-lg' name='buynow'><i class='fa fa-bolt' aria-hidden='true'></i> Buy Now! </button>
                                    </nav>
                                </div>
                                <div id='abm'>
                                    <nav class='navbar navbar-expand fixed-bottom' style={{ padding: '0px' }}>
                                        <button type='hidden' class='btn btn-outline-primary btn-block d-none'>Primary</button>
                                        <button type='submit' class='btn btn-primary btn-block btn-lg' onClick={() => onAdd(item)} >Add To Cart</button>
                                        <button type='submit' class='btn btn-warning btn-block btn-lg' name='buynow'><i class='fa fa-bolt' aria-hidden='true'></i> Buy Now! </button>
                                    </nav>
                                </div>



                            </div>
                        </div>

                    </div>

                )

            }


            <div class="container">

                <div id="ratingDetails" style={{ textAlign: 'left' }}> 		<br /><br />
                    <div class="row">
                        <div class="col-sm-3">
                            <h4>Rating and Reviews</h4>
                            <monospace>({rating} Rating and Reviews)</monospace>
                            <h2 class="bold padding-bottom-7">{ratingavg} <small>/ 5</small></h2>
                        </div>
                        <div class="col-sm-9">
                            <button data-toggle="collapse" data-target="#ratingSection" onClick={Ratepro} class="btn btn-outline-primary">Rate This Product</button>

                            {emailcheck ?

                                <div id="ratingSection" class="collapse">
                                    <div class="row">
                                        <div class="col-sm-12">

                                            <div class="form-group">
                                                <h4>Rate this product</h4>

                                                <select class="form-control" value={ratings} onChange={handleChange} required>
                                                    <option >Select Rating</option>
                                                    <option value="1">1 Star</option>
                                                    <option value="2">2 Star</option>
                                                    <option value="3">3 Star</option>
                                                    <option value="4">4 Star</option>
                                                    <option value="5" selected>5 Star</option>
                                                </select>


                                            </div>
                                            <div class="form-group">
                                                <label for="usr">Title*</label>
                                                <input type="text" class="form-control" value={title} onChange={(e) => { setTitle(e.target.value) }} id="title" name="title" required />
                                            </div>
                                            <div class="form-group">
                                                <label for="comment">Comment*</label>
                                                <textarea class="form-control" rows="5" id="comment" value={comments} onChange={(e) => { setComments(e.target.value) }} name="comment" required></textarea>
                                            </div>
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-info" onClick={saveRating} name="savr" id="saveReview">{revbtn}</button> <button type="button" class="btn btn-info" id="cancelReview">Cancel</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                : ""}

                        </div>
                    </div>

                    {
                        ratingreview.map((itemrr) =>
                            <div>


                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="review-block-name">By </div>
                                        <div class="review-block-date"> {itemrr.name}  </div>
                                    </div>
                                    <div class="col-sm-9" style={{ textAlign: 'left' }}>
                                        <div class="review-block-rate">
                                            <h6><div><span class="badge badge-success"> {itemrr.ratingnumber} <i class="fa fa-star" aria-hidden="true"></i></span></div></h6>


                                        </div>
                                        <div class="review-block-title"> {itemrr.title}</div>
                                        <div class="review-block-description">{itemrr.comments}      </div>
                                    </div>
                                </div>
                                <hr />


                            </div>

                        )

                    }




                </div>


                <br />
                <div class="row">
                    <div class="col-sm-12">
                        <h3 align={"left"}><i> &nbsp; &nbsp;Similar Items</i></h3>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <OwlCarousel options={optionswn}  >
                            {
                                similar.map((item) =>
                                    <NavLink to={"/proddetails/" + item.id + "/" + item.name} style={{ textDecoration: 'none' }} onClick={() => Similaritems()}>

                                        <div class="item" style={{ width: '205px', marginLeft: '-200px' }}>
                                            <img src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "150px", width: "200px", marginRight: '5px', paddingRight: '0px', borderRadius: "10px" }} alt={item.thumbnail} />
                                            <div style={{ backgroundColor: 'black', opacity: '.7', marginTop: '-25px', marginRight: '5px', paddingRight: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#FFF' }} >{item.name}</div>
                                        </div>
                                    </NavLink>

                                )
                            }

                        </OwlCarousel>
                    </div>
                </div>



            </div>




            <Footer />
        </div>
    );
}
export default Proddetails;