import React from 'react';
import {Link} from "react-router-dom";

export function Dashsidelist(){
    return(
      <div>
<ul class="list-group">
<li class="list-group-item  bg-dark text-white">My Account</li>
    <Link class="list-group-item list-group-item-action" to="/dashboard">Dashboard</Link>      
    <Link class="list-group-item list-group-item-action" to="/accountdetails">Account Details</Link>      
    <Link class="list-group-item list-group-item-action" to="/orders">Orders</Link>      
    <Link class="list-group-item list-group-item-action" to="/wallet">Wallet</Link>      
    <Link class="list-group-item list-group-item-action" to="/deliveryaddress">Delivery Address</Link>      
    <Link class="list-group-item list-group-item-action" to="/logout">Logout</Link>          
</ul>          
      </div>  
    )
}