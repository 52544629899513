import React, { useState } from 'react';
import Navhead from './Navhead';
import { NavLink } from 'react-router-dom';

function SearchProduct(props) {
    let cartItems = props.cartItems;
    const [data, setData] = useState([]);
    const [datatrend, dataSetTrend] = useState([]);

    fetch("https://foodyinn.in/api/fetchtrending.php").then((resulttrend) => {
        resulttrend.json().then((resptrend) => {

            dataSetTrend(resptrend);
            console.warn("RESPindian", resptrend);
        })
    });


    async function search(key) {

        if (key.length > 1) {
            let result = await fetch('https://foodyinn.in/api/searchall.php?prod=' + key);
            result = await result.json();
            setData(result);
            console.warn(key);
            console.warn(result);

        }
        else if (key.length < 1) {
            setData("");
        }
        else {
            setData("");
        }


    }


    return (
        <div>
            <Navhead cartItemnav={cartItems.length} />

            <div class="form-group">
                <input style={{ borderColor: 'skyblue', fontStyle: 'italic', fontWeight: '600' }} type="text" class="form-control" onChange={(e) => { search(e.target.value) }} placeholder="Search for food items" name="name" />
            </div>
            {
                data.length > 1 ?
                    <div class="list-group">




                        {
                            data.map((item) =>
                                <NavLink align={'left'} to={"/proddetails/" + item.id} style={{ textDecoration: 'none', width: '100%' }} class="list-group-item list-group-item-action list-group-item-dark">


                                    <img align={'left'} src={"https://foodyinn.in/products/" + item.thumbnail} style={{ height: "50px", width: "50px", borderRadius: "15px", padding: '10px' }} alt={item.thumbnail} />
                                    <h6 align={'left'} style={{ padding: '10px' }}>{item.name}</h6>



                                </NavLink>

                            )
                        }

                    </div>
                    : null
            }



            <h4 style={{ textAlign: 'left' }}><i>&nbsp;Trending Searches</i></h4>

            <div class="list-group">

                {
                    datatrend.map((itemtrend) =>
                        <NavLink align={'left'} to={"/prodetails?item=" + itemtrend.id + "&title=" + itemtrend.name} style={{ textDecoration: 'none', width: '100%' }} class="list-group-item list-group-item-action list-group-item-dark">


                            <img align={'left'} src={"https://foodyinn.in/products/" + itemtrend.thumbnail} style={{ height: "50px", width: "50px", borderRadius: "15px", padding: '10px' }} alt={itemtrend.thumbnail} />
                            <h6 align={'left'} style={{ padding: '10px' }}>{itemtrend.name}</h6>



                        </NavLink>

                    )
                }

            </div>

        </div>
    );
}
export default SearchProduct;